



















import anotaciones_dietaModule from "@/store/modules/anotaciones_dieta-module";
import { Component, Vue } from "vue-property-decorator";

@Component({ $_veeValidate: { validator: "new" } })
export default class Anotaciones_dieta extends Vue {
  created() {
    anotaciones_dietaModule.getanotaciones_dieta(this.$route.params.id);
  }
  public get anotacionesdieta() {
    return anotaciones_dietaModule.anotaciones_dieta;
  }
}
